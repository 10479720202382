@use "sass:map";
/* make the customizations */
$theme-colors: ("primary": #3500D3,
    "dark": #282828,
    "secondary": #240090,
    "danger": #ff4c4c,
    "info": #190061,
    "warning":#0C0032,
    "dull" : #eeeeee,
    "black": #050505,
    "success": #60ff9f);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";


@import "/src/assets/fonts/fonts.css";
//@import url('https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&display=swap');

* {
    box-sizing: border-box;
}

html,
body {
    background-color: map.get($theme-colors, "black");
    font-family: 'Poppins', cursive;
    color: map.get($theme-colors, "dull");
    min-height: 100vh;
    overflow: hidden;
}

/* Code for Selection Text */
::-moz-selection {
    background: map.get($theme-colors, "primary");

    color: map.get($theme-colors, "white");
}

::selection {
    background: map.get($theme-colors, "primary");

    color: map.get($theme-colors, "white");
}

a {
    font-weight: bold;
    text-decoration: none;
}



.hero {
    font-size: 7.5em;
    font-family: 'Poppins', cursive;
    line-height: "115px";
}

.blur {
    filter: blur(5px);
}

.hover-box {
    background-image: linear-gradient(to bottom,
            transparent calc(0% - 5px),
            map.get($theme-colors, "primary") 0%,
            map.get($theme-colors, "dark") 100%);
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline;
    transition: 0.5s ease;
}

.hover-box:hover {
    background-size: 0%;
}

h1 {
    color: map.get($theme-colors, "primary");
}

img.dark-shadow {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 1));
}

.inner-shadow {
    box-shadow: inset 0 0 20px #000000;
}

.social-pill {
    margin-left: -10px;
    padding-left: 25px;
    color: #ffffff;
    background-color: map.get($theme-colors, "dark");
}

.social-pill:hover {
    color: map.get($theme-colors, "dark");
    background-color: map.get($theme-colors, "dull");
}

.no-select {
    user-select: none;
    /* Standard */
}

.phase-container {
    width: 85vw;
    height: 70vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map.get($theme-colors, "info");
    border-bottom: map.get($theme-colors, "primary");
}


.part-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 100%;
    position: relative;
}

.box {
    height: fit-content;
    width: fit-content;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.overlay {
    position: absolute;
    top: 50px;
    left: 50px;
    bottom: 50px;
    right: 50px;
    background: rgb(32, 32, 32);
    opacity: 0.3;
    /* top-left | top-right | bottom-right | bottom-left */
    border-radius: 0 0 35px 35px;
}


.gradient-button {
    color: #ffffff;
    box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)),
        linear-gradient(-55deg, map.get($theme-colors, "primary"), map.get($theme-colors, "black"));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #000000 inset;
}

.gradient-button:hover {
    transition: 0.5s ease;
    box-shadow: none;
    color: white;
}